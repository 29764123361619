import React from 'react'
import { Link as RouterLink } from 'gatsby'

import { Box, Text, Link, Space } from 'components'

const BackToContentFooter = () => {
  return (
    <Box
      sx={{
        borderTop: '1px solid',
        borderColor: 'colorBlue10',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Link
          as={RouterLink}
          to="/ratgeber"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 0,
            color: 'darkNavy40',
            textDecoration: 'none',
            justifyContent: 'center',
            width: ['100%', 'auto'],
            py: 3,
          }}
        >
          <Box
            as="svg"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              width: 12,
              height: 9,
              mr: 2,
            }}
          >
            <path
              d="M11.25 4.49984C11.25 4.84984 11.0167 5.08317 10.6667 5.08317H2.73334L5.24167 7.59151C5.475 7.82484 5.475 8.17484 5.24167 8.40817C5.125 8.52484 5.00834 8.58317 4.83334 8.58317C4.65834 8.58317 4.54167 8.52484 4.425 8.40817L0.925004 4.90817C0.866671 4.84984 0.808337 4.7915 0.808337 4.73317C0.750004 4.6165 0.750004 4.4415 0.808337 4.2665C0.866671 4.20817 0.866671 4.14984 0.925004 4.0915L4.425 0.591504C4.65834 0.358171 5.00834 0.358171 5.24167 0.591504C5.475 0.824837 5.475 1.17484 5.24167 1.40817L2.73334 3.9165H10.6667C11.0167 3.9165 11.25 4.14984 11.25 4.49984Z"
              fill="#8093AB"
            />
          </Box>
          zurück zum Ratgeber
        </Link>
      </Box>
    </Box>
  )
}

export default BackToContentFooter
